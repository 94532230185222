import React from 'react'
import AlgoliaPlaces from 'algolia-places-react'
import { observer, inject } from 'mobx-react'

import SearchBox from './Searchbox'
import RadiusSelect from './RadiusSelect'
import styles from './searchbar.module.scss'

function GeoSearchBox(props) {
  const {
    searchStore,
    id = 'geosearch',
    inputProps = {},
    ...searchboxProps
  } = props

  const onChange = ({ query, rawAnswer, suggestion, suggestionIndex } = {}) => {
    if (suggestion.latlng) {
      const { lat, lng } = suggestion.latlng
      searchStore.setLocation(lat, lng)
    }
  }

  // When in mini mode
  const toggleThatJawn = () => {
    if (typeof props.onToggle === 'function') {
      props.onToggle('geosearch')
    }
  }
  // location-input
  const label = (
    <svg
      className={styles.mapPin}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 288 512"
    >
      <path
        fill="currentColor"
        d="M144 0C64.47 0 0 64.47 0 144c0 74.05 56.1 134.33 128 142.39v206.43l11.01 16.51c2.38 3.56 7.61 3.56 9.98 0L160 492.82V286.39c71.9-8.05 128-68.34 128-142.39C288 64.47 223.53 0 144 0zm0 256c-61.76 0-112-50.24-112-112S82.24 32 144 32s112 50.24 112 112-50.24 112-112 112zm0-192c-44.12 0-80 35.89-80 80 0 8.84 7.16 16 16 16s16-7.16 16-16c0-26.47 21.53-48 48-48 8.84 0 16-7.16 16-16s-7.16-16-16-16z"
      />
    </svg>
  )

  return (
    <SearchBox label={label} id={id} {...searchboxProps}>
      <AlgoliaPlaces
        {...inputProps}
        id={id}
        className={styles.placesInput}
        onChange={onChange}
      />
      <div className={styles.vr} />
      <div className={styles.radiusInput}>
        <RadiusSelect id="radius-input" values={[50, 25, 15, 10, 5]} />
        <label htmlFor="radius-input">mi</label>
      </div>
    </SearchBox>
  )
}
export default inject('searchStore')(GeoSearchBox)
