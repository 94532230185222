import React, { useEffect, useState, useMemo, useRef } from 'react'
import useResizeObserver from 'use-resize-observer'
import { inject } from 'mobx-react'
import { navigate } from 'gatsby'

import styles from './searchbar.module.scss'
import SearchBox from './Searchbox'
import GeoSearchBox from './GeoSearchBox'

function Searchbar(props) {
  const {
    className,
    forceFocus = false,
    miniModeAt = 500, // px
  } = props
  /**
   * @param {MouseEvent|KeyboardEvent} e
   */
  const onSubmit = e => {
    if (e.key && e.key !== 'Enter') {
      return
    }
    if (isMiniMode && openInput === 'search') {
      return (
        setOpenInput('geosearch'),
        // AlgoliaPlaces ref won't cooperate so we're doing it this way
        document.querySelector('#geosearch').focus()
      )
    }
    if (typeof props.onSubmit === 'function') {
      props.onSubmit({
        state: props.searchStore.searchState,
        string: props.searchStore.queryString,
      })
    } else {
      navigate(`/search${props.searchStore.queryString}`)
    }
  }

  /**
   * What to do when an input is focused
   */
  const onFocus = e => {
    e.target.select()
    setFocused(true)
  }

  const onBlur = e => {
    setFocused(false)
  }

  const [ref, width] = useResizeObserver()
  const [isMiniMode, setMiniMode] = useState(false)
  const [openInput, setOpenInput] = useState('search')
  const [focused, setFocused] = useState(false)

  useEffect(() => {
    if (width <= 1) {
      return
    }
    setMiniMode(width <= miniModeAt)
  }, [width])

  return (
    <div
      className={[
        styles.container,
        className,
        isMiniMode && styles.mini,
        (focused || forceFocus) && styles.focused,
      ]
        .filter(Boolean)
        .join(' ')}
      onKeyUp={onSubmit}
      ref={ref}
    >
      <SearchBox
        id={'search'}
        minimize={openInput !== 'search'}
        onToggle={setOpenInput}
        inputProps={{
          placeholder: 'bikes, drones, sports...',
          onFocus,
          onBlur,
        }}
      />
      {/* For lazy reasons, this also contains the radius selection thing */}
      <GeoSearchBox
        id={'geosearch'}
        minimize={openInput !== 'geosearch'}
        onToggle={setOpenInput}
        inputProps={{
          placeholder: 'address, city, zip...',
          onFocus,
          onBlur,
        }}
      />
      <button className={styles.searchBtn} onClick={onSubmit}>
        Search
      </button>
    </div>
  )
}

export default inject('searchStore')(Searchbar)
