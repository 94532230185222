import React from 'react'
import styled from '@emotion/styled'
import Link from 'gatsby-link'

import { button } from 'utils/styles'

const ContactBtn = styled('a')`
  ${button.purple};
  ${button.big};
  flex: 1;
  margin-top: 20px;
`

const ConfirmRow = styled('div')`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 422px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`

export default class ConfirmExplanationElement extends React.Component {
  _clickMessage = e => {
    e.stopPropagation()
    let id = ''
    if (this.props.lending) {
      id = this.props.item.renter.id
    } else {
      id = this.props.item.loaner.id
    }
    navigate(`/message?recipent=${id}`)
  }

  render() {
    let { decision, rental } = this.props || {}
    return (
      <ConfirmRow>
        {decision == null ? (
          <div />
        ) : decision ? (
          <>
            <h4>Rental Request Confirmed</h4>
            <div>You will recieve an email confirming your request.</div>
            <div>
              The next step is to coordinate a pick up time with the borrower.
            </div>
          </>
        ) : (
          <>
            <h4>Rental Request Rejected</h4>
            <div>
              This request has been rejected. The borrower will be notified.
            </div>
          </>
        )}
        {rental.renter && (
          <Link to={`/message?recipent=${rental.renter.id}`}>
            <ContactBtn>Message the borrower</ContactBtn>
          </Link>
        )}
      </ConfirmRow>
    )
  }
}
