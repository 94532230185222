import React, { ChangeEvent, useState, useEffect } from 'react'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react-lite'

import styles from './searchbar.module.scss'
/**
 * This is a searchbox that is connected to the
 * main searchStore.
 */

const SearchBox = (props, ref) => {
  /**
   * @param {ChangeEvent} e
   */
  const onChange = e => {
    const value = e.target.value
    props.searchStore.setQuery(value)
  }

  // Dereference some props so that they aren't added to DOM when spread
  const {
    id = 'search',
    searchStore,
    minimize = false,
    onToggle,
    label,
    labelProps = {},
    children,
    inputProps = {},
  } = props

  // Only works when in mini mode
  const toggleThatJawn = () => {
    if (typeof onToggle === 'function') {
      onToggle(id)
    }
  }

  const renderLabel = label || (
    <svg
      className={styles.magGlass}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M508.5 481.6l-129-129c-2.3-2.3-5.3-3.5-8.5-3.5h-10.3C395 312 416 262.5 416 208 416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c54.5 0 104-21 141.1-55.2V371c0 3.2 1.3 6.2 3.5 8.5l129 129c4.7 4.7 12.3 4.7 17 0l9.9-9.9c4.7-4.7 4.7-12.3 0-17zM208 384c-97.3 0-176-78.7-176-176S110.7 32 208 32s176 78.7 176 176-78.7 176-176 176z"
      />
    </svg>
  )

  // Wrapping the input in a div is necessary so the the css is the same
  // for the 3rd party GeoSearchBox
  const renderInput = children || (
    <div>
      <input
        {...inputProps}
        id={id}
        value={searchStore.state.query}
        type="text"
        onChange={onChange}
      />
    </div>
  )

  return (
    <div
      className={[styles.searchInput, !minimize && styles.open]
        .filter(Boolean)
        .join(' ')}
      onClick={toggleThatJawn}
    >
      <label htmlFor={id} {...labelProps}>
        {renderLabel}
      </label>
      <div className={styles.inputWrapper}>{renderInput}</div>
    </div>
  )
}

export default inject('searchStore')(observer(SearchBox))
