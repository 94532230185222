import { useState, useCallback } from 'react'
import DatabaseService from 'services/DatabaseService'
import useAsyncEffect from './useAsyncEffect'
import firebase from 'firebase/app'

// Performs a shallow population
async function populate(data, opts) {
  const { fields = [] } = opts
  await Promise.all(
    fields.map(async field => {
      if (!data[field] instanceof firebase.firestore.DocumentReference) {
        return
      }
      const snap = await data[field].get()
      data[field] = snap.data()
      data[field].id = snap.id
    })
  )
  return data
}

/**
 * A hook that can load a document from a path
 * @returns {[Boolean, firebase.firestore.DocumentData]}
 */
export default function useFirestorePreloader(
  pathOrRef,
  onError,
  options,
  deps = []
) {
  const [response, setResponse] = useState([false, null, null])

  const throwError = msg => {
    typeof onError === 'function' && onError(new Error(msg))
  }

  const processSnap = useCallback(
    async snap => {
      if (!snap.exists) {
        return throwError(`Document at path ${snap.ref.path} does not exist`)
      }
      // Shallow population
      const snapData =
        options && options.populate
          ? await populate(snap.data(), options.populate)
          : snap.data()
      snapData.id = snap.id
      setResponse([true, snapData, snap])
    },
    [pathOrRef, ...deps]
  )

  useAsyncEffect(
    async onDestroy => {
      setResponse([false, null, null])
      if (!pathOrRef) {
        return throwError('No path specified')
      }
      /** @type {firebase.firestore.DocumentReference} */
      let ref = null
      if (
        pathOrRef instanceof firebase.firestore.DocumentReference ||
        pathOrRef instanceof firebase.firestore.Query
      ) {
        ref = pathOrRef
      } else {
        ref = DatabaseService.firestore.doc(pathOrRef)
      }

      // Subscribe to ref
      if (options.subscribe === true) {
        const unsub = ref.onSnapshot(snap => {
          processSnap(snap)
        })
        onDestroy(unsub)
      } else {
        processSnap(await ref.get())
      }
    },
    [pathOrRef, ...deps]
  )

  return response
}
