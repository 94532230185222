import React from 'react'
/**
 * A component for displaying a list of messages
 * For future implementation, messages need to be
 * grouped by rental+user rather than solely user or rental.
 * This should help lenders differentiate message chains.
 * @param {*} props
 */
export default function Messages(props) {
  // TODO: Extrapolate into a List component
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        padding: 10,
      }}
    >
      <div className={'s'}>Messages</div>
      <div>No Messages</div>
    </div>
  )
}
