import React from 'react'
import Link from 'gatsby-link'
import { navigate } from 'gatsby'
import qs from 'qs'

import '../rent-item/style.scss'
import * as routes from 'constants/routes'
import ConfirmationForm from 'components/Rent/Confirmation/ConfirmationForm'
import Layout from 'layouts/AuthenticatedPage'
import SEO from 'components/SEO/SEO'
import DatabaseService from 'services/DatabaseService'
import DatabasePreloader from 'components/DatabasePreloader/DatabasePreloader'
import AuthService from 'services/AuthService'

export class RentalConfirmationPage extends React.Component {
  componentDidMount() {
    if (AuthService.currentUser.uid != this.props.rental.lender.id) {
      console.error('Unauthorized Access To Rental')
      navigate('/search')
    }
  }

  onDecision = async decision => {
    try {
      await DatabaseService.setRentalDecisionById(
        this.props.rental.id,
        decision
      )
    } catch (error) {
      return error
    }
  }

  render() {
    return (
      <div id="rental-confirmation-page">
        <div className="content-wrap">
          <section className="page-title form-title">
            <h2>Rental Request</h2>
          </section>
          <ConfirmationForm
            rental={this.props.rental}
            onDecision={this.onDecision}
          />
        </div>
      </div>
    )
  }
}

export default ({ location }) => {
  return (
    <DatabasePreloader subscribe={true} map={`rentals/:id`} location={location}>
      {(rental, snap) => (
        <Layout location={location} displayFooter={false} showBeacon={true}>
          <SEO title="Rental Confirmation" />
          <RentalConfirmationPage rental={rental} />
        </Layout>
      )}
    </DatabasePreloader>
  )
}
