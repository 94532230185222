import React, { ChangeEvent } from 'react'
import { inject, observer } from 'mobx-react'
/**
 * This is a searchbox that is connected to the
 * main searchStore.
 */
@inject('searchStore')
@observer
export default class RadiusSelect extends React.Component {
  /**
   * Wait a small amount of time before setting the query
   * @param {ChangeEvent} e
   */
  onChange = e => {
    const value = e.target.value
    this.props.searchStore.setRadius(value)
  }

  render() {
    // Dereference some props so that they aren't added to DOM when spread
    const { searchStore, values = [50, 25, 10], ...selectProps } = this.props
    return (
      <select {...selectProps} onChange={this.onChange}>
        {values.map(value => (
          <option key={`radius-${value}`}>{value}</option>
        ))}
      </select>
    )
  }
}
