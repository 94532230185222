import React from 'react'
import styled from '@emotion/styled'
import { colors } from 'utils/styles'

const Tag = styled('span')`
  border-radius: 4px;
  color: ${colors.brand};
  padding: 6px 10px;
  background-color: ${colors.brandLight};
  font-weight: 600;
  text-transform: lowercase;
  font-size: 11px;
`

const TagRow = styled('div')`
  text-align: center;
`

export default class StatusTag extends React.Component {
  render() {
    let { status } = this.props
    return (
      <TagRow>
        <Tag>{status}</Tag>
      </TagRow>
    )
  }
}
