import React from 'react'
import styled from '@emotion/styled'

import { button } from 'utils/styles'

const ConfirmBtn = styled('a')`
  ${button.purple};
  ${button.big};
  flex: 1;
  margin-left: 30px;
`

const RejectBtn = styled('a')`
  ${button.default};
  ${button.big};
`

const BtnRow = styled('div')`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 422px;
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
`

export const ConfirmButton = props => {
  if (!props.onClick) {
    return <div />
  }
  return <ConfirmBtn {...props}>Confirm</ConfirmBtn>
}

export const RejectButton = props => {
  if (!props.onClick) {
    return <div />
  }
  return <RejectBtn {...props}>Reject</RejectBtn>
}

export const FormButtonElements = props => {
  return (
    <BtnRow>
      <RejectButton onClick={props.reject} />
      <ConfirmButton onClick={props.confirm} />
    </BtnRow>
  )
}
