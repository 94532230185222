/**
 * AuthService controls auth interactions
 *
 * This has been separated from the FirebaseService
 * just in case we decide to use a different auth method
 */

import FirebaseServiceBase from './FirebaseServiceBase'

export default new class AuthService extends FirebaseServiceBase {
  get currentUser() {
    return this.auth.currentUser
  }
  getCurrentUser = (cb, keepAlive = typeof cb === 'function') => {
    return new Promise((res, rej) => {
      const listener = this.auth.onAuthStateChanged(authUser => {
        res(authUser)
        if (typeof cb === 'function') {
          cb(authUser, listener)
        }
        // destroy listener if not using callbacks
        if (typeof cb !== 'function' || !keepAlive) {
          listener()
        }
      })
    })
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password)

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password).then(info => {
      // let ref = this.firestore.collection('users').doc(info.user.uid)
    })

  reauthenticate = password => {
    const credential = this.$firebase.auth.EmailAuthProvider.credential(
      this.auth.currentUser.email,
      password
    )
    return this.auth.currentUser.reauthenticateAndRetrieveDataWithCredential(
      credential
    )
  }

  doSignOut = () => this.auth.signOut()

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email)

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password)

  verifyPhone = async phone_number => {
    const userID = this.auth.currentUser.uid.toString()
    const response = await this.http(`users/${userID}/auth/phone/start`, {
      method: 'POST',
      body: JSON.stringify({ phone_number }),
    })
    // TODO: Explicitly check the error message returned here
    // This could be an internal server error
    if (!response.ok) {
      throw 'Invalid Phone Number'
    }
    const data = await response.json()
    return data
  }

  checkCode = async (phone_number, verification_code) => {
    const userID = this.auth.currentUser.uid.toString()
    const response = await this.http(`users/${userID}/auth/phone/check`, {
      method: 'POST',
      body: JSON.stringify({ phone_number, verification_code }),
    })
    // TODO: Explicitly check for what error is returned
    if (!response.ok) {
      throw 'Invalid Code'
    }
    const data = await response.json()
    return data
  }

  /**
   * Sends the user's id and access code to the cloud api backend
   * where the rest of the authentication will take place
   * @param {firebase.firestore.Firestore} firestore
   */
  stripeAuthentication = async code => {
    const userID = this.auth.currentUser.uid.toString()
    const response = await this.http(`users/${userID}/auth/stripe-express`, {
      method: 'POST',
      body: JSON.stringify({ code }),
    })
    // TODO: Check for specific error code
    if (!response.ok) {
      throw 'Auth Error'
    }
    const data = await response.json()
    return data
  }

  verifySocial = (facebook, linkedin, twitter, instagram) => {
    const userRef = this.firestore
      .collection('users')
      .doc(this.auth.currentUser.uid.toString())
    return userRef.update({
      facebook: facebook,
      linkedin: linkedin,
      twitter: twitter,
      instagram: instagram,
      socialVerified: false,
    })
  }
}()
