import React from 'react'
import styled from '@emotion/styled'
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome'
import {
  faBell,
  faEnvelope,
  faCaretDown as MenuIndicator,
} from '@fortawesome/free-solid-svg-icons'

import { colors, fonts, spacing } from 'utils/styles'
import ResponsiveDropdownList from 'components/ResponsiveDropdownList'
import NotificationsMenu from 'components/Notifications'
import QuickLinks from 'components/QuickLinks'
import Messages from 'components/Messages/Messages'
import { Observer } from 'mobx-react-lite'
import UserStateStore from 'state/UserStateStore'
import AuthService from 'services/AuthService'
import useMediaQuery from 'utils/hooks/useMediaQuery'

const QuickLinksContainer = styled('div')`
  display: flex;
  cursor: pointer;
  align-items: center;
  font-weight: normal;
  border-radius: 5px;
  padding: 5px 5px;
  transition: 200ms all ease-in-out;
  > * {
    margin: 0 5px;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`

const AvatarContainer = styled('div')`
  border: 2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 50%;
  cursor: pointer;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 200ms all ease-in-out;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`

const Avatar = styled('img')`
  width: 100%;
`

const DefaultAvatar = styled('svg')`
  border-radius: 50px;
  color: #adb5bd;
  height: 40px;
  width: 40px;
`

export default function ProfilePlate(props) {
  const authUser = AuthService.currentUser
  const isDekstop = useMediaQuery(`(min-width: 1200px)`)
  return (
    <ResponsiveDropdownList
      collapseAt={199}
      collapseLabel={2}
      style={{
        flex: 1,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
      data={[
        {
          order: 2,
          show: false,
          label: (
            <AvatarContainer>
              <FaIcon icon={faBell} />
            </AvatarContainer>
          ),
          item: <NotificationsMenu />,
        },
        {
          order: 1,
          show: false,
          label: (
            <AvatarContainer>
              <FaIcon icon={faEnvelope} />
            </AvatarContainer>
          ),
          item: <Messages />,
        },
        {
          order: 0,
          label: (
            <QuickLinksContainer>
              <AvatarContainer>
                {authUser.photoURL ? (
                  <Avatar src={authUser.photoURL} alt={authUser.name} />
                ) : (
                  <DefaultAvatar
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    data-prefix="fas"
                    data-icon="user-circle"
                    role="img"
                    viewBox="0 0 496 512"
                    data-ember-extension="1"
                  >
                    <path
                      fill="currentColor"
                      d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"
                    />
                  </DefaultAvatar>
                )}
              </AvatarContainer>
              <Observer>
                {() =>
                  UserStateStore.user &&
                  isDekstop && (
                    <span style={{}}>{UserStateStore.user.firstName}</span>
                  )
                }
              </Observer>
              <FaIcon icon={MenuIndicator} />
            </QuickLinksContainer>
          ),
          item: <QuickLinks user={authUser} />,
        },
      ]}
    />
  )
}
