import { useEffect } from 'react'
/**
 * A utility function to call an async function withinn an effect
 * and not worry about
 * @param {*} callbackFn
 * @param {*} destroyFn
 * @param {*} deps
 */
export default function useAsyncEffect(callbackFn, destroyFn, deps) {
  useEffect(
    () => {
      let onDestroy = null
      typeof callbackFn === 'function' &&
        callbackFn(cb => {
          onDestroy = cb
        })
      return () => {
        typeof onDestroy === 'function' && onDestroy()
        typeof destroyFn === 'function' && destroyFn()
      }
    },
    typeof destroyFn !== 'function' ? destroyFn : deps
  )
}
