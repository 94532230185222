import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { Link } from 'gatsby'

import Searchbar from '../Searchbar'
import styles from './navbar.module.scss'
import Mellow from '../shared/Header/Mellow'
import auth from 'services/AuthService'
import ProfilePlate from './Profile'
import { NavbarUIState } from './NavbarConfig'

export default observer(function Navbar(props) {
  // NavbarUIState state take precedence
  const augmentedProps = {
    ...props,
    ...(props.disableStore ? {} : NavbarUIState.state),
  }
  const {
    className,
    style = {},
    searchBarProps = {},
    mode = 'sticky',
    hideLeft = false,
    hideRight = false,
    showSearchBar = true,
    scrollEffects = true,
    overlay = false,
  } = augmentedProps

  const ref = useRef()

  const [isScrolled, setScrolled] = useState(false)
  const [didScrollUp, setDidScrollUp] = useState(false)
  const [isStuck, setIsStuck] = useState(false)
  const [offset, setOffset] = useState(null)

  useLayoutEffect(() => {
    if (!scrollEffects) {
      return
    }
    let lastPos = 0
    const scrollListener = () => {
      setScrolled(window.pageYOffset > 55)
      // uncomment the following two lines for an interesting effect
      // setDidScrollUp(window.pageYOffset > lastPos)
      // lastPos = window.pageYOffset
    }
    document.addEventListener('scroll', scrollListener)
    return () => document.removeEventListener('scroll', scrollListener)
  }, [scrollEffects])

  function signInHeader() {
    return (
      <>
        <Link to={'/about'} className={styles.actionButton}>
          About
        </Link>
        <Link to={'/users/login'} className={styles.actionButton}>
          Sign In
        </Link>
        <Link
          to={'/users/sign_up'}
          className={[styles.actionButton, styles.lastBtn].join(' ')}
        >
          Create an Account
        </Link>
      </>
    )
  }

  function rightComponent() {
    const { rightComponent = true } = augmentedProps
    if (rightComponent !== true) {
      return rightComponent
    }
    return <>{auth.auth.currentUser ? <ProfilePlate /> : signInHeader()}</>
  }

  function leftComponent() {
    const { leftComponent = true } = augmentedProps
    if (leftComponent !== true) {
      return leftComponent
    }
    return (
      <>
        <Link className={styles.logo} to="/">
          <Mellow height={20} />
        </Link>
        <Link to="/search" className={styles.actionButton}>
          Browse
        </Link>
        <Link to="/submit" className={styles.actionButton}>
          List An Item
        </Link>
      </>
    )
  }
  return (
    <div
      ref={ref}
      style={style}
      className={[
        className,
        styles.wrapper,
        styles[mode],
        overlay ? styles.overlay : '',
        isStuck ? styles.stuck : '',
        isScrolled ? styles.scrolled : '',
        didScrollUp ? styles.scrolledUp : '',
      ].join(' ')}
    >
      <div
        className={[styles.container, styles[mode], isStuck && styles.stuck]
          .filter(Boolean)
          .join(' ')}
      >
        <div
          className={[styles.section, styles.left, hideLeft && styles.hidden]
            .filter(Boolean)
            .join(' ')}
        >
          {leftComponent()}
        </div>
        <div
          className={[
            styles.section,
            styles.center,
            !showSearchBar && styles.hidden,
          ]
            .filter(Boolean)
            .join(' ')}
        >
          {showSearchBar && <Searchbar {...searchBarProps} />}
        </div>
        <div
          className={[styles.section, styles.right, hideRight && styles.hidden]
            .filter(Boolean)
            .join(' ')}
        >
          {rightComponent()}
        </div>
      </div>
    </div>
  )
})
