import React, { useMemo } from 'react'
import firebase from 'firebase/app'
import qs from 'qs'

import useFirestorePreloader from 'utils/hooks/useFirestorePreloader'
import { navigate } from 'gatsby'

/**
 * Loads an item from the database
 * @param {{ location?, path?, map?, populate?, loadingComponent? }} props
 */
export default function DatabasePreloader(props) {
  const {
    loadingComponent = null,
    map = null,
    location = null,
    subscribe = false,
    onError,
    match = {},
    populate = [],
    ref,
    children,
  } = props

  /** @see https://reach.tech/router/api/Match */
  let { uri = null, path = null, ...keys } = match || {}

  const pathOrRef = useMemo(() => {
    // if location is provided, it takes precedence
    if (location && location.search) {
      keys = { ...keys, ...qs.parse(location.search.slice(1)) }
      uri = location.search
    }
    if (typeof uri === 'string') {
      if (typeof map !== 'string') {
        return uri
      }
      const mappedPath = map.replace(/\/:([^/?]+)/, (full, $1) => {
        if (!keys[$1]) {
          console.warn(`${$1} was not found in uri ${uri}`)
        }
        return `/${keys[$1] || $1}`
      })
      return mappedPath
    }
    if (ref instanceof firebase.firestore.DocumentReference) {
      return ref
    }
    return ''
  }, [uri, ref])

  const [ready, data, snap] = useFirestorePreloader(
    pathOrRef,
    e => {
      console.warn(e)
      navigate('/')
    },
    {
      subscribe,
      populate,
    },
    [uri]
  )

  if (ready === false) {
    return loadingComponent
  }
  return children(data, snap)
}
