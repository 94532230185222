import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import {
  faBoxOpen as inventoryIcon,
  faHistory as historyIcon,
  faUser as profileIcon,
  faCog as settingsIcon,
  faSignOutAlt as signOutIcon,
} from '@fortawesome/free-solid-svg-icons'

import styles from './QuickLinks.module.scss'
import auth from 'services/AuthService'

function QuickLink(props) {
  const { icon, children, ...rest } = props
  return (
    <Link className={styles.quickLink} {...rest}>
      {icon && <Icon icon={icon} className={styles.icon} />}
      {children}
    </Link>
  )
}

export default function QuickLinks(props) {
  const { user } = props
  return (
    <div className={styles.container}>
      {/* TODO: ProfileInfo Component should go here */}
      <span className={styles.quickInfo}>{user.email}</span>
      <div className={styles.quickLinksWrapper}>
        <QuickLink to="/dashboard" icon={profileIcon}>
          Profile
        </QuickLink>
        <QuickLink to="/dashboard/rentals" icon={historyIcon}>
          Rental History
        </QuickLink>
        <QuickLink to="/dashboard/items" icon={inventoryIcon}>
          My Inventory
        </QuickLink>
        <QuickLink to="/dashboard/settings" icon={settingsIcon}>
          Settings
        </QuickLink>
      </div>
      <div className={styles.signOutButton}>
        <QuickLink to="/search" icon={signOutIcon} onClick={auth.doSignOut}>
          Sign Out
        </QuickLink>
      </div>
    </div>
  )
}
