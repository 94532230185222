import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Link } from 'gatsby'
import { observer } from 'mobx-react-lite'

import UiStateStore from 'state/UiStateStore'
import DatabaseService from 'services/DatabaseService'
import AuthService from 'services/AuthService'

/**
 * A somewhat lazy way of testing a feature
 * without having to integrate it into the flow.
 *
 * Simply type the code anywhere on the page and
 * the callback function will run
 * @param {string} text
 */
function useKonamiCode(code, fn) {
  useEffect(() => {
    const queue = []
    const callback = e => {
      queue.push(e.key)
      while (queue.length > code.length) {
        queue.shift()
      }
      if (queue.length === code.length && queue.join('') === code) {
        console.log(`Konami Code ${code} activated!`)
        fn()
      }
    }
    document.addEventListener('keyup', callback)
    return () => document.removeEventListener('keyup', callback)
  }, [])
}

/**
 * This component observes the UiStateStore
 * verificationRequestListener function and
 * opens a modal when it's defined.
 * @param {*} props
 */
function VerifyPasswordModal(props) {
  useKonamiCode('vpmtest', () => {
    UiStateStore.requestVerification(({ error, isCancelled }, close) => {
      if (error) {
        close(false)
        console.error(error)
        return
      }
      close(true)
      if (isCancelled) {
        console.log('PASSWORD NOT VERIFIED')
      } else {
        console.log('PASSWORD VERIFIED')
      }
    })
  })

  const [loading, setLoading] = useState(false)

  const ref = useRef()
  // Submit
  const callback = useCallback(async e => {
    setLoading(true)
    const result = { isCancelled: false, error: null }
    const password = ref.current.value
    try {
      await AuthService.reauthenticate(password)
    } catch (e) {
      result.error = e
    }
    // This part is a bit hacky and could use some refactoring
    UiStateStore.completeVerificationRequest(result, () => {
      setLoading(false)
    })
  }, [])
  // Cancel the request
  const cancel = useCallback(() => {
    UiStateStore.completeVerificationRequest({ isCancelled: true, error: null })
  }, [])

  if (typeof UiStateStore.verificationRequestListener !== 'function') {
    return null
  }

  return (
    <div
      style={{
        position: 'absolute',
        background: 'rgba(0,0,0,0.3)',
        display: 'flex',
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        zIndex: 999999999,
      }}
    >
      <div
        style={{
          width: 300,
          height: 150,
          padding: 10,
          justifySelf: 'center',
          alignSelf: 'center',
          background: 'white',
          borderRadius: 5,
        }}
      >
        {loading ? (
          <div> Verifying </div>
        ) : (
          <>
            <div>
              <p>Please verify your password</p>
            </div>
            <div>
              <input
                ref={ref}
                type="password"
                placeholder="password"
                onKeyPress={e => e.key === 'Enter' && callback()}
              />
              <button onClick={callback}>Verify</button>
            </div>
            <div>
              <Link to="/users/login" onClick={cancel}>
                Use a different account
              </Link>
              <span onClick={cancel}>cancel</span>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default observer(VerifyPasswordModal)
