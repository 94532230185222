export const BASE = 'https://www.borrowmellow.com'
export const HOME = '/'
export const SEARCH = '/search'
export const SIGN_UP = '/users/sign_up'
export const SIGN_IN = '/users/login'
export const PASSWORD_FORGET = '/pw-forget'
export const ACCOUNT = '/dashboard'
export const EDIT_ITEM = '/dashboard/items?edit={{id}}'
export const PROFILE = '/profile?u={{id}}'
export const ITEM = '/item/{{id}}'
export const RENTAL_CONFIRMATION = '/rental-confirmation?id={{id}}'
