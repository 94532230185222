import { action, observable, computed } from 'mobx'

/**
 * Simple mobx global ui state component.
 * If a state change is needed in order
 * to affect the UI, then this is the place
 * to put it.
 *
 * For other domains, simply create a new
 * store for that.
 * @see https://mobx.js.org/best/store.html
 */

class UiStateStore {
  _verificationRequestListener = observable.box(null)

  @computed get verificationRequestListener() {
    return this._verificationRequestListener.get()
  }

  /**
   * If an action requires re-verification of the user's
   * password, call this function to open a modal requiring
   * the user's password.
   */
  @action requestVerification(callback) {
    if (this.verificationRequestListener) {
      throw 'Only one verification listener can be registereed at a time. \
            This error indicates a control flow problem. Ensure the previous request \
            has been cleared before creating a new request.'
    }
    this._verificationRequestListener.set(callback)
  }
  @action completeVerificationRequest(value, onOpComplete) {
    const close = (closeDialogue = true) => {
      if (closeDialogue === true) {
        this._verificationRequestListener.set(null)
      }
      if (typeof onOpComplete !== 'function') {
        return
      }
      onOpComplete()
    }
    this.verificationRequestListener(value, close)
    // if true is returned then clear the listener
  }
}

export default new UiStateStore()
