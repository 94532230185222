/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/core'

import { colors, breakpoints } from '../../../utils/styles'

export default function(props) {
  const { height = '20px', color } = props

  return (
    <svg
      viewBox="0 0 334 66"
      style={{ height }}
      // css={css({
      //   display : 'inline-block',
      //   height : 20
      // })}
    >
      <g fill={props.color || '#222'}>
        <path d="m16.8 25.7c2.5-1.3 4.9-2.7 7.4-3.7 4.9-2 9.9-2.2 14.9-.3 2.1.8 3.8 2.1 5.1 3.9.2.3.4.5.6.8 1.7-1 3.4-2 5.1-2.9 4.5-2.4 9.3-3.3 14.4-2.8 7 .7 11.9 5.7 11.9 12.8.1 6.7 0 13.3 0 20 0 3.1.6 6 2.7 8.6-1.5 1.2-3.1 1.9-4.9 2.3-2.9.8-5.9.8-8.9.2-3.7-.7-5.7-2.9-5.8-6.7-.1-6.3 0-12.5-.1-18.8 0-1.2-.2-2.5-.5-3.7-.5-1.7-1.8-2.5-3.4-2.8-3.1-.5-5.7 1-8.1 2.8-.2.2-.3.7-.3 1.1v26.6c0 1-.2 1.3-1.2 1.4-4.9.5-9.9.5-14.8 0-.8-.1-1.1-.3-1.1-1.2 0-8.1 0-16.2 0-24.3 0-.9-.1-1.9-.2-2.8-.5-2.4-2.2-3.7-4.8-3.7-2.7 0-4.9 1.4-7 2.8-.2.2-.3.7-.3 1.1v26.4c0 1.4 0 1.4-1.4 1.6-4.9.5-9.8.5-14.7 0-.7-.1-.9-.4-.8-1 0-3 0-5.9 0-8.9 0-10.7 0-21.4 0-32.1 0-1.6.1-1.7 1.7-1.7 2.5 0 5 0 7.4.2 3.2.3 5.6 1.9 6.9 4.8.1-.1.2 0 .2 0z" />
        <path d="m256.8 23.2c2-1.5 4.3-2.3 6.7-2.7 2.6-.4 5.2-.4 7.8.2 3.4.8 5.2 3.3 5.2 7.3v18.4c0 .9.1 1.9.3 2.8.7 2.7 3.4 4.2 6.5 3.3 1.8-.6 3.5-1.7 5.2-2.6.3-.2.4-.8.4-1.3 0-8.8 0-17.6 0-26.4 0-.4 0-.8 0-1.3 5.7-.7 11.4-.7 17.1 0v1.3 24.1c0 .7 0 1.5.1 2.2.6 3.4 3 5 6.4 4.1 1.9-.5 3.7-1.6 5.4-2.6.3-.1.4-.8.4-1.3 0-8.8 0-17.6 0-26.4 0-.4 0-.9 0-1.6 4-.1 7.9-.1 11.7-.2 1 0 1.9.1 2.9.1.6 0 .9.2.9.9-.2 7.7-.3 15.3-.7 23-.2 3.6-.8 7.2-2 10.6-.7 2.2-2 3.8-4 5-3.4 2.2-7.1 3.2-11 3.6-2.8.3-5.6.5-8.4.6-2.5.1-5.1.1-7.6-.2-3.7-.4-6.7-2.1-8.8-5.2-.1-.1-.2-.2-.4-.4-.5.3-1.1.7-1.6 1-3.6 2.3-7.3 4-11.5 4.7-3.4.5-6.8.3-10.1-.8-4.8-1.7-8-5.9-8.1-11-.1-6.6-.1-13.2-.1-19.9 0-1.3-.1-2.6-.2-3.9-.3-1.7-1.1-3.6-2.5-5.4z" />
        <path d="m223.4 65.3c-5 .1-9.8-.8-14.3-3.2-6.4-3.4-9.7-8.9-10.6-15.9-.5-4.1-.2-8.1 1.1-12.1 2.4-6.8 7.3-10.9 14.1-13 4.4-1.3 8.8-1.5 13.3-1.1 4.1.3 8 1.2 11.5 3.3 5.5 3.1 8.7 7.9 9.9 14 .8 4.5.8 9-.7 13.4-2.5 7.5-8 11.8-15.5 13.6-2.9.7-5.9.8-8.9 1.2.1 0 .1-.1.1-.2zm8.2-22.8c-.1 0-.2 0-.3 0 0-3-.2-6-1.3-8.8-.5-1.4-1.4-2.6-2.9-3.1-4.4-1.7-9.7-.7-11 5.4-1 4.6-.8 9.2.1 13.8.8 3.9 2.9 5.6 6.8 5.7 4.1.1 6.4-1.5 7.3-5.4.8-2.6 1-5.1 1.3-7.6z" />
        <path d="m101.3 46.8c-.2 4.1 2.5 7.3 6.6 7.9 4.3.7 8.5 0 12.7-.9 1.4-.3 2.7-.7 4-1 .4-.1.9 0 1.2.3 2.1 1.8 3.2 4.1 3.8 6.7.1.3-.2.8-.5.9-4.1 2.2-8.5 3.4-13.1 4.1-4.4.6-8.8.7-13.2.2-3.7-.4-7.2-1.5-10.3-3.5-3.5-2.2-5.9-5.4-7.1-9.4-1.8-5.8-2.1-11.6-.4-17.5 1.9-6.8 6.4-11.1 12.9-13.3 7.9-2.5 15.8-2.5 23.4 1.1 6.4 3.1 9.9 8.2 9.9 15.4 0 2.7-.3 5.3-1 7.8-.2.8-.6 1-1.4 1-8.7 0-17.4 0-26 0-.6.2-1 .2-1.5.2zm-.2-9.2h14.3c.4-3.1-.7-6-3-7.3-2.7-1.5-5.5-1.6-8.1 0-2.7 1.6-2.9 4.4-3.2 7.3z" />
        <path d="m194.8 54.2c1.3 2.9 1.5 5.8 1.1 8.8 0 .3-.4.6-.6.7-5.5.6-11.1 1.4-16.6.3-5.3-1-7.7-4.1-8.1-9.8-.1-2-.1-4-.1-6.1 0-15.3 0-30.5 0-45.8 0-1.6.1-1.7 1.7-1.6 2.8 0 5.6 0 8.5.2 4.2.3 6.4 2.6 6.4 6.9.1 10.2 0 20.4 0 30.5v11.3c0 3.2 1.4 4.6 4.6 4.6z" />
        <path d="m139.4 28.3c0-8.7 0-17.4 0-26 0-1.6.1-1.7 1.6-1.7 2.8 0 5.6 0 8.4.2 4.5.3 6.6 2.7 6.6 7.2v25.8 15.8c0 3 1.4 4.4 4.5 4.5h2.5c.6 0 .8.2 1.1.7 1.1 2.6 1.1 5.3.9 8 0 .3-.5.8-.9.8-4.7.6-9.4 1.1-14.1.6-1.6-.2-3.3-.5-4.9-1.1-3.9-1.5-5.5-4.8-5.6-8.7-.2-6-.1-12.1-.1-18.1 0-2.5 0-5.2 0-8z" />
      </g>
    </svg>
  )
}
