export default function(props) {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{ maxWidth: 608, textAlign: 'center', fontSize: 24 }}>
        Something has gone terribly wrong here. Sorry about that. <br />
        Don't worry, a bug report has been sent automatically. <br />
        In the meantime try clearing your cache and reloading the page.
      </div>
    </div>
  )
}
