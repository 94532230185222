import React from 'react'
import Link from 'gatsby-link'
import { navigate } from 'gatsby'
import Moment from 'moment'

import './RentalDisplay.scss'
import StatusTag from 'components/shared/Tags/StatusTag'
import { getRoute } from 'utils'

Moment.locale('en')

export default class RentalDisplay extends React.Component {
  state = {
    img: '',
    name: '',
    firstName: '',
    num_days: 1,
  }

  convertToMoney = numb => {
    if (!numb) return
    return numb.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  toDate = item => {
    if (item && typeof item.toDate === 'function') {
      item = item.toDate()
    }
    return Moment(item).format('MMM Do')
  }

  render() {
    let { showStatus, status, showPrices, type, rental } = this.props

    if (!rental) {
      return null
    }

    let price_d,
      total_price_nofee,
      service_fee,
      total_price = ''

    if (rental) {
      price_d = this.convertToMoney(rental.price_d)
      total_price_nofee = this.convertToMoney(rental.total_price_nofee)
      service_fee = this.convertToMoney(rental.service_fee)
      total_price = this.convertToMoney(rental.total_price)
    }

    return (
      <div className="rental-display-container">
        <div className="rental-subcontainer">
          <div className="flex-column">
            <div className="rental-row">
              <div className="display-img-container">
                <img className="rental-img" src={rental.item_img} />
              </div>
              <div className="rental-title-container">
                <p className="display-title">{rental.item_name}</p>
                {type == 'lend' ? (
                  <p className="hint-text">
                    Lender:{' '}
                    <a
                      onClick={e => {
                        navigate(getRoute('PROFILE', { id: rental.lender.id }))
                        e.stopPropagation()
                      }}
                    >
                      {rental.lender_name}
                    </a>
                  </p>
                ) : (
                  <p className="hint-text">
                    Borrower:{' '}
                    <a
                      onClick={e => {
                        navigate(getRoute('PROFILE', { id: rental.renter.id }))
                        e.stopPropagation()
                      }}
                    >
                      {rental.renter_name}
                    </a>
                  </p>
                )}
                <p className="hint-text">
                  From: {this.toDate(rental.start_date)}
                  &nbsp;-&nbsp;
                  {this.toDate(rental.end_date)}
                </p>
              </div>
            </div>
            {showPrices && (
              <>
                <div className="display-container-row">
                  <p className="normal-txt">
                    <span>{rental.item_price / 100}</span> x {rental.num_days}{' '}
                    Day:
                  </p>
                  <p className="normal-txt">
                    <span>${total_price_nofee}</span>
                  </p>
                </div>
                <div className="display-container-row">
                  <p className="normal-txt">Service Fee:</p>
                  <p className="normal-txt">
                    <span>${service_fee}</span>
                  </p>
                </div>
                <div className="rental-total-row">
                  <p className="normal-txt">Total:</p>
                  <p className="normal-txt">
                    <span>${total_price}</span>
                  </p>
                </div>
              </>
            )}
            {showStatus && (
              <>
                {status && <StatusTag status={status} />}
                <div className="rental-footer">
                  <Link
                    to={`/dashboard/rentals${type == 'rent' ? '#lend' : ''}`}
                  >
                    <p className="view-on-dashboard">
                      View rental on your dashboard
                    </p>
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
}
