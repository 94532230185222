import React from 'react'

import { FormButtonElements } from './elements/ButtonElements'
import ConfirmExplanationElement from './elements/ConfirmExplanationElement'
import RentalDisplay from 'components/shared/Rental/RentalDisplay'

export default function ConfirmationForm(props) {
  const { rental } = props

  const { decision_date = null, approved = null } = rental

  const submit = async (e, approved) => {
    e.preventDefault()
    try {
      const error = await props.onDecision(approved)
      if (error) {
        console.error(error)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <RentalDisplay
        type="rent"
        rental={rental}
        showPrices={approved === null}
        showStatus={approved !== null}
        status={approved ? 'Approved' : 'Rejected'}
      />
      {approved === null ? (
        <FormButtonElements
          confirm={e => submit(e, true)}
          reject={e => submit(e, false)}
        />
      ) : (
        <>
          <ConfirmExplanationElement
            decision={approved ? 'Approved' : 'Rejected'}
            rental={rental}
          />
        </>
      )}
    </>
  )
}
