import React, { useState, useEffect } from 'react'
import Portal from 'components/Portal'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faTimes as closeIcon } from '@fortawesome/free-solid-svg-icons'

import styles from './Sidebar.module.scss'
import { useGesture } from 'react-with-gesture'

export default function Sidebar(props) {
  const {
    children,
    direction = 'right',
    isOpen = false,
    className = 'sidebar',
    ...containerProps
  } = props
  const [open, setOpen] = useState(isOpen)
  // TODO: This is just a stop gap. It should be more robust in the future
  const bind = useGesture(e => {
    if (!e.down) {
      return
    }
    if (e.direction[0] > 0 && e.distance > 80) {
      close()
    }
  })
  // If props change, change state
  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])
  // Prevent scrolling on body
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = null
    }
    return () => {
      document.body.style.overflow = null
    }
  }, [open])

  const close = () => {
    setOpen(false)
    if (typeof props.onClose === 'function') {
      props.onClose()
    }
  }
  return (
    <Portal>
      <div
        className={[styles.backdrop, open ? styles.open : ''].join(' ')}
        onClick={close}
        {...bind()}
      />
      <div
        {...containerProps}
        className={[
          className,
          styles.container,
          styles[direction],
          open ? styles.open : '',
        ].join(' ')}
        {...bind()}
      >
        <div className={styles.closeButton} onClick={close}>
          <Icon icon={closeIcon} />
        </div>
        {children}
      </div>
    </Portal>
  )
}
