export const isBrowser = () => typeof window !== 'undefined'

export const deepClean = obj => {
  const copy = { ...obj }
  for (let key in copy) {
    if (/^\$|^\_/.test(key)) {
      delete copy[key]
    } else if (copy[key].constructor === Object) {
      copy[key] = deepClean(copy[key])
    }
  }
  return copy
}

export * from './getRoute'
