import React, { useState, useEffect } from 'react'

import styles from './styles.module.scss'

export default function NotificationsMenu(props) {
  const { maxItems } = props
  useEffect(() => {
    // TODO: Subscribe to firestore notification updatees
    return () => null // TODO: Unsubscribe from notification updates
  })
  return (
    <div className={styles.container}>
      <div>Notifications</div>
      <div>No Notifications Yet</div>
    </div>
  )
}
