import * as routes from 'constants/routes'

/**
 * Takes an enum and data object and returns a properly formatted
 * relative url
 * @param {keyof routes} route
 */
export function getRoute(route, data = {}) {
  let href = routes[route]
  for (let key in data) {
    href = href.replace(`{{${key}}}`, data[key])
  }
  return href
}
