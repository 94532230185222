/**
 * This is the firebase service base class.
 * Any other service that intends to use firebase should
 * extend this base class.
 */
import React from 'react'
import { schema } from 'mellow-resources'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

import keys from 'constants/keys'
import UiStateStore from 'state/UiStateStore'
console.log(firebase.firestore)
// initialize F+ and register schema
import FirestorePlus, { plugins } from 'firestore-plus'

const schemaPlugin = new plugins.SchemaPlugin({
  autoValidate: keys.NODE_ENV !== 'production',
  // Try toggling these if experiencing problems
  validateOnUpdate: true,
  validateOnSet: true,
  validateOnUpdate: true,
})

if (typeof window !== 'undefined') {
  //FirestorePlus(firebase).use(schemaPlugin)
}

for (let collection in schema.collectionMap) {
  const model = schema.collectionMap[collection]
  schemaPlugin.model(collection, model)
}

const config = keys.Firebase

// Collection keys (as needed). Plural, singular, and short forms included
export const COLLECTIONS = {
  items: 'fleet',
  users: 'users',
  rentals: 'rentals',

  item: 'fleet',
  user: 'users',

  // id defaults to rentals becuse....why not?
  id: 'rentals',

  i: 'fleet',
  u: 'users',
}

export default class FirebaseServiceBase {
  constructor() {
    if (typeof window !== 'undefined') {
      if (!firebase.apps.length) {
        firebase.initializeApp(config)
      }
      this.$firebase = firebase
      this.firestore = firebase.firestore()
      this.auth = firebase.auth()
    }
    this.Context = new React.createContext(this)
    this.Consumer = this.Context.Consumer
    this.Provider = this.Context.Provider
  }

  COLLECTIONS = COLLECTIONS

  /**
   * Automatically sets headers
   * @param {String} route
   * @param {RequestInit} data
   * @returns {Promise<Response>}
   */
  http(route, data = {}) {
    if (!route) {
      return Promise.reject('Route not defined')
    }
    route = route.replace(/^\/+/, '')
    return new Promise((resolve, reject) => {
      const action = async (attemptedVerification = false) => {
        const { headers = {}, ...rest } = data
        const url = `${keys.REST_API.BASE_URL}/${route}`.replace(/\/$/, '')
        // This negative lookbehind version is better, but not supported on all browsers:
        // const url = `${keys.REST_API.BASE_URL}/${route}`.replace(/(?<!:)\/+/g,"/").replace(/\/$/, "")
        const idToken = await this.auth.currentUser.getIdToken(true)

        const response = await fetch(url, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
            ...headers,
          },
          mode: 'cors',
          ...rest,
        })

        // If user needs to sign in again
        if (response.status === 401) {
          if (attemptedVerification === true) {
            reject('Verification Failed')
          }
          return UiStateStore.requestVerification(
            async ({ error, isCancelled }, close) => {
              if (error) {
                close(false)
                return
              }
              close(true)
              if (isCancelled) {
                reject(error || 'Unauthorized')
              } else {
                const response = await action(true)
                resolve(response)
              }
            }
          )
        }

        // If user is not allowed to do whatever it is they just tried to do
        if (response.status === 403) {
          return reject('Forbidden')
        }
        // Everything went okay
        resolve(response)
      }
      action()
    })
  }

  /**
   * Gets a single document. Accepts various argument type for ref
   * Can also do a shallow population if populate is supplied
   * @param {string | { collection? : keyof COLLECTIONS, id? : string, path : string} } ref
   */
  getDocument = async (ref, asData = true, popFields = false) => {
    if (typeof ref === 'string') {
      ref = this.firestore.doc(ref)
    }
    if (ref instanceof Array) {
      ref = this.firestore.doc(
        ref.map(e => e.replace(/^\/|\/$/g, '')).join('/')
      )
    } else if (ref.constructor === Object) {
      const { collection = '', path = '', id = '', populate = false } = ref
      // Remove leading and trailing slashes and duplicated slashes
      ref = this.firestore.doc(
        `${COLLECTIONS[collection]}/${path}/${id}`
          .replace(/\/+/g, '/')
          .replace(/^\/|\/$/g, '')
      )
      popFields = populate
    }
    if (!(ref instanceof firebase.firestore.DocumentReference)) {
      throw new Error(
        `Could not convert first argument: ${ref} to firebase DocumentReference`
      )
    }
    const snap = await ref.get()
    if (asData === true) {
      const data = snap.data() || {}
      if (popFields) {
        for (let field of popFields) {
          if (!(data[field] instanceof firebase.firestore.DocumentReference)) {
            continue
          }
          // save original reference
          data[`$${field}`] = data[field]
          // backwards compatability
          // create new reference
          data[field] = await this.getDocument(data[field])
        }
      }
      return {
        $ref: ref,
        $snap: snap,
        $exists: snap.exists,
        // this field is for backwards compatability
        id: snap.id,
        ...data,
      }
    }
    return snap
  }
}
